import React, { useState, HTMLAttributes } from "react";

import { IsoDate } from "@app/components/calendar/Calendar.types";
import { generateMonth } from "@app/components/calendar/calendar.utils";
import {
  CalendarContext,
  CalendarContextState
} from "@app/components/calendar/CalendarContext";
import { Days } from "@app/components/calendar/Days/Days";
import { Heading } from "@app/components/calendar/Heading/Heading";
import { Selector } from "@app/components/calendar/Selector/Selector";
import { yieldToMain } from "@app/utils/scheduler-yield";

interface Props extends HTMLAttributes<HTMLDivElement> {
  /** An interface to the date management library. See adapter section below */
  adapter: CalendarContextState;

  /** The selected date at initilalization. */
  selectedDate?: IsoDate;
  state_outbound_date?: IsoDate;
  showRange?: boolean;

  /** The number of months supported by the Calendar. Starting month is always the current month. default: `6` */
  maxMonthsDisplay?: number;

  /** If enable, Calendar's days are deselectabled. defautl: `false` */
  deselectableDate?: boolean;

  /** The minimum selectable date. default: today */
  minSelectableDate?: IsoDate;

  /** The callback executed when a date is selected. */
  onDateSelection?: (date: IsoDate | null) => void;
}

/**
 *  Calendar provides a React date picker with few configurable options. It's designed to use the date management library of your choice.
 *
 *  [Storybook](https://busbud.github.io/design-system/?path=/docs/components-data-display-calendar--page)
 *
 * @param props
 * @param props.className
 * @param props.adapter
 * @param props.selectedDate
 * @param props.minSelectableDate
 * @param props.maxMonthsDisplay
 * @param props.deselectableDate
 * @param props.onDateSelection
 * @param props.state_outbound_date
 * @param props.showRange
 */
export const Calendar: React.FC<Props> = ({
  adapter,
  selectedDate,
  minSelectableDate,
  state_outbound_date,
  maxMonthsDisplay = 6,
  deselectableDate = false,
  onDateSelection,
  showRange = false,
  ...other
}) => {
  const startingDate = minSelectableDate || adapter.getToday();
  const [date, setDate] = useState(selectedDate || null);
  const [currentIndex, setCurrentIndex] = useState(
    getSelectedDateMonthIndex(date, startingDate, maxMonthsDisplay)
  );

  const onNextMonth = async (): Promise<void> => {
    await yieldToMain();
    if (currentIndex < maxMonthsDisplay - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const onPreviousMonth = async (): Promise<void> => {
    await yieldToMain();
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const onDaySelection = async (date: IsoDate | null) => {
    await yieldToMain();

    setDate(date);
    if (onDateSelection) {
      onDateSelection(date);
    }
  };

  const isMinMonth = currentIndex === 0;
  const isMaxMonth = currentIndex === maxMonthsDisplay - 1;

  const current_month = generateMonth(
    startingDate,
    currentIndex,
    adapter.getMonthYear,
    adapter.getWeekArray
  );

  return (
    <CalendarContext.Provider value={adapter}>
      <div className="relative mt-025" {...other}>
        <Selector
          month={current_month.label}
          isMinMonth={isMinMonth}
          isMaxMonth={isMaxMonth}
          onNextMonth={onNextMonth}
          onPreviousMonth={onPreviousMonth}
        />

        <Heading />

        <Days
          selectedDate={date || null}
          rangeStartDate={state_outbound_date}
          weeks={current_month.weeks}
          monthNumber={current_month.number}
          year={current_month.year}
          minSelectableDate={startingDate}
          onDateSelection={onDaySelection}
          deselectableDate={deselectableDate}
          showRange={showRange}
        />
      </div>
    </CalendarContext.Provider>
  );
};

function monthDiff(d1: Date, d2: Date): number {
  let months = (d2.getUTCFullYear() - d1.getUTCFullYear()) * 12;
  months -= d1.getUTCMonth();
  months += d2.getUTCMonth();

  return months <= 0 ? 0 : months;
}

function getSelectedDateMonthIndex(
  selectedDate: string | null,
  startingDate: string,
  maxMonthsDisplay: number
): number {
  if (!selectedDate) {
    return 0;
  }

  return Math.min(
    monthDiff(new Date(startingDate), new Date(selectedDate)),
    maxMonthsDisplay - 1
  );
}
