import type { Tracker } from "@app/tracking/tracker";

import { clickMenu } from "../../../tracking/authentication-tracking";
import {
  PageSection,
  openDropdown,
  selectDropdownItem
} from "../../../tracking/dropdown-tracking";
import {
  clickLink,
  TargetPage,
  PageSection as LinkPageSection
} from "../../../tracking/link-tracking";

export class LandingPagesUI {
  public static init(window: Window) {
    return new LandingPagesUI(window);
  }

  private body: HTMLElement;
  private tracker: Tracker;

  public constructor(private window: Window) {
    this.body = window.document.body;

    this.tracker = this.window.tracker;

    this.initializeFooterSelect();
    this.initHeader();
  }

  public initializeFooterSelect() {
    const page_section = PageSection.Footer;
    this.body
      .querySelectorAll('[data-interactive="redirection"]')
      .forEach(selector => {
        const id = selector.getAttribute("id");
        if (id) {
          selector.addEventListener("click", () => {
            this.tracker.asyncTrack(openDropdown(id, page_section));
          });
        }
        selector.addEventListener("change", evt => {
          if (id) {
            this.tracker.track(selectDropdownItem(id, page_section));
          }
          this.window.location.href =
            evt.target &&
            "value" in evt.target &&
            typeof evt.target.value === "string"
              ? evt.target.value
              : "";
        });
      });
  }

  public initHeader() {
    const account_button = this.window.document.getElementById("account-menu");
    const suspended_operations_link = this.window.document.getElementById(
      "js-suspensed-operation-banner-link"
    );

    if (account_button) {
      account_button.addEventListener("click", () => {
        this.tracker.track(clickMenu("account", "header"));
      });
    }

    if (suspended_operations_link) {
      suspended_operations_link.addEventListener("click", () => {
        this.tracker.asyncTrack(
          clickLink(
            LinkPageSection.SUSPENDED_OPERATIONS_BANNER,
            TargetPage.ZENDESK_ARTICLE
          )
        );
      });
    }
  }
}

export default LandingPagesUI;
